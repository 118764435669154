<template>
	<nav class="nav-bar">
		<NuxtLink
			v-for="item in menuStore.getMenuMobile"
			:key="item.name"
			:to="item.link"
			class="nav-bar__item"
			active-class="active"
			:exact-active-class="item.name === 'Главная' ? 'active' : ''">
			<span class="nav-bar__picture">
				<svg class="icon nav-bar__icon">
					<use :xlink:href="useSprite() + `#${item.icon_code}`"></use>
				</svg>

				<span
					v-if="item.counter > 0"
					class="nav-bar__count"
					>{{ item.counter }}</span
				>
			</span>

			<span class="nav-bar__title">{{ item.name }}</span>
		</NuxtLink>
	</nav>
</template>

<script setup>
	import { useMenuStore } from "@/store/menu/menu";
	import { useSprite } from "@/composables/useSprite";

	const menuStore = useMenuStore();
	await menuStore.fetchMenu();
</script>

<style lang="scss">
	@import "nav-bar";
</style>
